
.nav-top{
    width: 100%;
    height: 50px;
    background-color: #fff;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px; 
    z-index: 2;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

}
a{
  text-decoration: none;
  color: #333;
}
.topLeft,.topRight{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center; 
}


.topCenter{
    flex: 6;
    display: flex;
    justify-content: center;
     cursor: pointer;
}


.topIcon{
    font-size: 20px;
    margin-right: 15px;
    color: #333;
    cursor: pointer;
    
}
.topIcon:hover{
    color: #00c2cb;
   
}

.topImg{
    width: 100px;
    height: 100px;
    object-fit: cover;
     transform:translate(-10%,-0%);

}
.topImg:hover{
    	position: relative;
	border-radius: 50px;
    border-color: #66afe9;
    box-shadow: 0 0 8px rgba(0,194,203, 0.6); 
}


.topImg {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.topList{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
   
}

.topListItem{
    margin-right: 20px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    position: relative;
    
}
.topListItem:hover{
   color: #00c2cb;
   
}
.topListItem:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.topListItem:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.p-img{
    width: 100%;
}

@media screen and (max-width: 480px){
    .nav-top{
 
      /* width: 100%; 
      align-items: center;
      position: absolute; */
      display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: space-between;
  width: 100%;
  flex-basis: auto;
    }

    .topLeft {
        padding-left: 20px;
    }
    .topCenter {
      margin-left: 100px;
    }

    .topRight .topListItem{
     font-size: 15px ;
    }

}