.c{
    height: 80vh;
}

.c-bg{
width: 20px;
height: 80vh;
background-color: #00c2cb;
position: absolute;
}

.c-wrapper{
    padding: 50px;
    display: flex;
}

.c-left{
    flex: 1;
}

.c-right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-title{
    font-size: 80px;
    width: 80%;
  
}

.c-info-item{
    display: flex;
    align-items: center;
    margin: 50px 0px;
    font-weight: 400;
    width: 70%;
}

.c-icon{
    width: 30px;
    height: 30px ;
    margin-right: 20px;
}

.c-desc{
    font-weight: 300;
}

form{
    margin-top: 20px;

}
input{
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #333;
    border-radius: 5px;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
}

.apple{
    	border-image: linear-gradient(45deg, #00c2cb, #000080) 1;
}   

.apple:focus{
    outline:solid 1px #00c2cb;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: none;
}

textarea{
    width: 100%;
    margin: 10px 0px;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 5px;
    resize: none;
}

button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 4px;
  position: relative;
  background-color: #00c2cb;
  border: none;
  color: #fff;
  padding: 20px;
  width: 150px;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  /* box-shadow: 0 5px 15px #193047; */
  /* box-shadow: rgba(0, 0, 0) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 4px;
  margin-top: 10px;
}

button:hover {
  background: #fff;
  /* box-shadow: 0px 2px 10px 5px #00c2cb; */
  /* box-shadow: rgba(0, 194, 203, 1) 0px 10px 40px 4px; */
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  /* border: 1px solid #f4f4f4; */
  color: #00c2cb;
}

button:after {
  content: "";
  background: #1abc9c;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

button:focus { outline:0; }

@media screen and (max-width: 480px){
    .c-wrapper{
        flex-direction: column;
        padding: 0px 50px;
        margin-top: 100px;
       
    }
    .c-title{
        font-size: 50px;
    }

    .c-info-item{
        margin: 20px 0px;
        width: 100%;
    }
    .c-desc{
        display: none;
    }

    form{
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
    }
    input{
        width: 35%;
        height: 40px;
        margin: 10px;
        margin-left:0px ;
    }
    button{
        margin-top: 10px;
    }
}