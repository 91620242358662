.i{
    display: flex;
    height: 100vh;
   
}

.i-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.i-right{
    flex: 1;
    position: relative;
}

.i-left-wrapper{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro{
font-size: 30px;
font-weight: 300;
}

.i-name{
    font-size: 80px;
}

.i-title{
    height: 50px;
    overflow: hidden;
}
.i-title-wrapper{
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25%{
        transform: translateY(-50px);
    }
    50%{
        transform: translateY(-100px);
    }
    75%{
        transform: translateY(-150px);
    }
    100%{
        transform: translateY(-200px);
    }
}

.i-title-item{
    height: 90px;
    font-size: 30px;
    font-weight: bold;
    color: #00c2cb;
    display: flex;
    align-items: center;
}

.i-scroll{
    width: 30px;   
    height: 30px;
    position: absolute;
    bottom: 20px;
}

.i-img{
    width: 100%;
    height: 112%;
    object-fit: cover;
    position: absolute;
    
}

/* .i-right .i-img::after{
    content: '';
    position: absolute;
    left: 243px;
    top: 150px;
    height: 50%;
    width: 20%;
    background-color: #00c2cb;
    border: 7px solid #00c2cb;
    box-shadow: 12px 10px 12px rgba(0, 0, 0, 50%);
    z-index: -1;
} */

.i-bg{
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    background-color: #00c2cb;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 500;
    right: 0;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    
}

@media screen and (max-width: 480px){
    .i{
        flex-direction: column;
    }
    .i-left-wrapper{
        padding: 10px;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 100%;
    }
    .i-desc{
        display: none;
    }
}
