.footer{
    background-color:#00c2cb;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.footer a{
    text-decoration: none;
    color: #333;
   
}
.footer ul{
    list-style: none;
}

.footer-logo{
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5rem;
    margin: 0 auto 3rem;
}

.footListItem{

    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    
}
.footListItem:hover{
   color: #333;
   
}
.footListItem:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footListItem:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer-socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.footer-socials a{
    background: #333;
    color: #fff;
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
     box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.footer-socials a:hover{
    background: #fff;
    color: #00c2cb;
    padding: 0.8rem;
    /* border: 1px solid transparent; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  
  
}
.footer-socials a:active{

     box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
       transform: translateY(4px);
}

.footer-copyright{
    margin-bottom: 2rem;
    color: #333;
}


@media screen and (max-width: 480px){
    .footer{
        flex-direction: column;
        padding: 10px 50px;
        margin-top: 150px;
        height: 100%;
    }

    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
        margin-right: 40px;
    }

    .footer-socials{
        margin-bottom: 2.6rem;
    }
   
}