.a{
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right{
    flex:1;
}

.a-card{
    width: 50%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}
img.a-img {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
img.a-img{
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-2px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-2px); }
}

.a-card.bg{
    position: absolute;
    top: 50px;
    left: 90px;
    background-color:#41d0ba ;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;   */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  
}

.a-img{
    width: 155%;
    height: 100%;
    position: absolute;
    top: -58px;
    left: -55px;
    object-fit: cover;
    
}

.a-title{
    font-weight: 500;
    
}
.a-sub{
    margin: 20px 0px;
    text-align: justify;
}

.a-desc{
    font-weight: 300;
    /* padding-right:100px ; */
    padding-right: 20px;
    text-align: justify;
}

@media screen and (max-width: 480px){
    .a{
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
    .a-sub{
        text-align: center;
    }
    .a-left{
        width: 100%;
    }
    .a-img{
        position: absolute;
        top: -28px;
        object-fit: cover;
    }
    .a-card{
        height: 32vh;  
    }
    .a-right{
        padding: 20px;
    }
}
