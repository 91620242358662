.p{
    width: 30%;
    height: 32vh;
    margin: 20px 10px;
    border: 2px solid rgba(243, 242, 242);
   /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
   box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
   border-radius: 10px 10px 0px 0px;
   overflow: hidden;
   
}

.p-browser{
    height: 20px;
    background-color: rgba(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 1;
}

.p-circle{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #00c2cb ;
    margin: 3px;
}
.p-img{
    width: 100%;
    transition: all 26s ease;
}

.p:hover .p-img{
    transform: translateY(-100%);
}

@media screen and (max-width: 480px){
    .p{
        width: 40%;
        height: 20vh;
    }
} 